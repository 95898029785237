import React from "react";
import { Heading, Button, Text, Flex } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function OurServiceCard({ title, description, link }) {
  return (
    <Flex
      flexDirection="column"
      as={Link}
      href={link}
      borderRadius="lg"
      boxShadow="lg"
      p="6"
      border="1px solid #eaeaea"
      transition="all 0.2s"
      _hover={{
        boxShadow: "lg",
        transform: "scale(1.01) translateY(-1%)",
      }}
      textAlign="center"
      alignSelf="stretch"
      height="100%"
    >
      <Heading as="h4" size="lg" fontWeight="medium" mb="6">
        {title}
      </Heading>

      <Text mb="6">{description}</Text>

      <Button
        as={Link}
        href={link}
        variant="solid"
        colorScheme="red"
        size="lg"
        mt="auto"
        alignSelf="center"
      >
        Learn More
      </Button>
    </Flex>
  );
}

export default OurServiceCard;

OurServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
