import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Button, Center, Grid, GridItem, Heading } from "@chakra-ui/react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Container from "../components/ui/Container";
import Tech from "../components/Tech";
import OurServiceCard from "../components/OurServiceCard";

function IndexPage() {
  return (
    <Layout>
      <Seo
        title="BITDECODERS | ADVANCE CODING & CLOUD CONSULTANCY IN INDIA"
        meta={[
          {
            name: "keywords",
            content:
              "website development company, website developer near me, website designing company, ecommerce website developer, web developer companies, indian web design company, website design and development company, web development agency, best web development company, web design and development services, ecommerce website development company, web development company near me, ecommerce web development company, ecommerce web design company, custom web design services, website development company near me, web design and development company, best web designer, top website development company, custom web development company, best website developers, web design & development company, website development agency, webdesign company, best ecommerce website development company, business website development, web and mobile app development companies, web design & development services, ecommerce website designing company, top web design company, website design & development company, mobile app designing company, best web designing companies, london web development company, best indian web design company, web development companies services",
          },
          {
            name: "description",
            content:
              "We specialize in providing website designing and development services including solution integration and offshore IT outsourcing to small and medium-sized businesses (SMB) across the globe. Go with us and you can benefit from our experience, with an amazing range of skills, deep vertical industries expertise, and excellence in top technologies, like Html, Html5, Node JS, React JS, Next JS, Gatsby, Swift, Kotlin, Flutter, Data Base Management, and various other custom application fields.",
          },
        ]}
      />

      <Box position="relative">
        <StaticImage
          src="../images/unsplash_m_HRfLhgABo.png"
          alt="Bitdecoders Homepage Banner image"
          layout="fullWidth"
        />

        <Center
          position="absolute"
          left="0"
          bottom="10"
          width="full"
          bg="gray.500"
          p={{ base: "2", md: "3", lg: "4" }}
          color="white"
          opacity="0.95"
        >
          <Heading
            as="h1"
            textAlign="center"
            fontSize={{ base: "lg", md: "3xl", lg: "4xl" }}
            fontWeight="medium"
          >
            WEB DEVELOPMENT AND DESIGN
          </Heading>
        </Center>
      </Box>

      <Container fluid>
        <div className=" flex flex-col my-12">
          <h1 className="text-center font-bold text-2xl ">
            Want to give your{" "}
            <span className="text-red-500 font-bold "> BUSINESS</span> an{" "}
            <span className="text-red-500 font-bold "> ONLINE PRESENCE</span>
          </h1>
          <h5 className="text-center text-sm mt-2">
            Custom Website Design &amp; Development Solution For Your Business
          </h5>
          <p className="text-center py-6 text-lg">
            {" "}
            <span className="text-red-500 font-bold  "> BITDECODERS </span> is
            the team name of WELLSOUL PRIVATE LIMITED. This team works in the
            field of Programming Code. Currently, we deal in WEBSITE DESIGN
            &amp; DEVELOPMENT, eCommerce DESIGN &amp; DEVELOPMENT and MOBILE APP
            DESIGN &amp; DEVELOPMENT. We give your business or portfolio a
            powerful website and online presence which not only caters to your
            specific business needs but helps you build better clientele and
            improves your visibility online.
          </p>
          <Button
            as={Link}
            href="/about/"
            className="mt-4"
            colorScheme="red"
            mx="auto"
          >
            About Us
          </Button>
        </div>

        {/* OUR SERVICES SECTION */}

        <Box my="14" id="services">
          <Heading
            as="h2"
            textAlign="center"
            fontWeight="medium"
            fontSize="4xl"
            textTransform="uppercase"
            mb="6"
          >
            Our <span className="text-red-500">Services</span>
          </Heading>

          <Grid
            my="4"
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              xl: "repeat(4, 1fr)",
            }}
            templateRows={{
              base: "repeat(4, 1fr)",
              md: "repeat(2, 1fr)",
              xl: "repeat(1, 1fr)",
            }}
            gap="6"
          >
            <GridItem>
              <OurServiceCard
                title="Responsive Website"
                description={`With the popularity of smartphones in today’s world, the chances
              of your website being opened on a mobile device are higher than
              ever. People find it more convenient to look for a business
              online through a mobile device than a desktop. Thus, it becomes
              important for you to have a website that is flexible with its
              Design and can adapt with both a desktop setup and a mobile
              screen resolution. Responsive designs are the ones to go within
              such a scenario!`}
                link="/progressive-or-responsive-web-app/"
              />
            </GridItem>

            <GridItem>
              <OurServiceCard
                title="Mobile App Design & Development"
                description={`The app plays a vital role in driving potential customers. Our
            development will ensure that your mobile app should create a
            fantastic first impression. Our team of highly qualified app
            developers, UI experts, and advisors are ready to work for your
            business's mobile app success.`}
                link="/mobile-app-design-and-development/"
              />
            </GridItem>

            <GridItem>
              <OurServiceCard
                title="ECommerce Design & Development"
                description={`E-commerce websites have shown massive success in recent times.
            To build an effective E-commerce website, one needs to have
            proper knowledge of the product, the purchase cycle, payment
            gateways, customer attraction, etc. Our team is well-equipped
            with all the information required to develop an E-commerce
            website and has shown a remarkable result to our existing
            clients.`}
                link="/e-commerce-design-and-development/"
              />
            </GridItem>

            <GridItem>
              <OurServiceCard
                title="Website Development"
                description={`Web development is the work involved in developing a website for
            the Internet. Web development can range from developing a simple
            single static page to complex web applications. As a website
            designing company, we are well versed with technologies for Web
            Design &amp; Development. Our Website Designing team has
            experienced and skilled individuals capable of bringing your
            vision on screen. We would be happy to be your website designing
            company`}
                link="/website-development/"
              />
            </GridItem>
          </Grid>
        </Box>

        {/* technologies component  */}

        <Tech />
      </Container>
    </Layout>
  );
}

export default IndexPage;
